(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

(function () {
  var WaitlistSignup, isWaitlistFormValid, sameDates;

  sameDates = function () {
    return swal(LANG.oops, LANG.sessions_on_same_date, 'error');
  };

  WaitlistSignup = function (elem) {
    var sessionid;
    sessionid = elem.data('id');
    return swal({
      'title': LANG.register_interest,
      'html': '<p>' + LANG.register_interest_info + '<br /><br /> <input class="form-control" type="text" id="interest-name-' + sessionid + '" name="name" placeholder="' + LANG.name + '" required /><br /> <input class="form-control" type="text" id="interest-email-' + sessionid + '" name="email" placeholder="' + LANG.email + '" required /><br /> <input class="form-control" type="text" id="interest-contact-' + sessionid + '" name="contact" placeholder="' + LANG.contact_no + '" required /> </p>',
      showCancelButton: true,
      closeOnConfirm: false,
      allowEscapeKey: false,
      allowOutsideClick: false
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal.enableLoading();
        if (isWaitlistFormValid(elem)) {
          return $.ajax({
            url: '/signups',
            method: 'POST',
            data: {
              name: $('#interest-name-' + elem.data('id')).val(),
              email: $('#interest-email-' + elem.data('id')).val(),
              contact: $('#interest-contact-' + elem.data('id')).val(),
              type: $('#interest-type-' + elem.data('id')).val(),
              id: $('#interest-id-' + elem.data('id')).val(),
              _token: $('#interest-token-' + elem.data('id')).val()
            }
          }).done(function (msg) {
            return swal(LANG.request_sent, LANG.request_sent_desc, 'success');
          }).fail(function (response, textStatus) {
            var errorList, errors;
            errors = Object.keys(response.responseJSON);
            errorList = $('<ul>');
            errors.forEach(function (elem, index) {
              var error;
              error = '<li>' + response.responseJSON[elem][0] + '</li>';
              return errorList.append(error);
            });
            return swal(LANG.error, errorList, 'error');
          });
        } else {
          console.log('Error condition is being called');
          swal.disableLoading();
          return swal({
            'title': LANG.oops,
            'text': LANG.all_form_fields_required,
            'type': 'error',
            closeOnConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then(function (isConfirm) {
            if (isConfirm) {
              return WaitlistSignup(elem);
            }
          });
        }
      }
    });
  };

  isWaitlistFormValid = function (elem) {
    console.log(elem.data('id'));
    if ($('#interest-name-' + elem.data('id')).val().length <= 0) {
      return false;
    } else if ($('#interest-email-' + elem.data('id')).val().length <= 0) {
      return false;
    } else if ($('#interest-contact-' + elem.data('id')).val().length <= 0) {
      return false;
    } else {
      return true;
    }
  };

  $(document).ready(function () {
    var enrolmart_nlpopup_delay, enrolmart_nlpopup_expires, enrolmart_open_nlpopup;
    if (!$('.session-selector').length) {
      $('.add-to-cart-btn').prop('disabled', false);
    }
    $('.register_interest_button').on('click', function (e) {
      e.preventDefault();
      return WaitlistSignup($(this));
    });
    $('.session-selector').on('change', function () {
      var currentSelected, currentSelectedDate, dateAlreadySelected, dateSelectedTwice, stillEmpty;
      stillEmpty = false;
      dateAlreadySelected = 0;
      dateSelectedTwice = false;
      currentSelected = 'select[name="' + $(this).prop('name') + '"] option:selected';
      currentSelectedDate = $(currentSelected).data('date');
      $('.session-selector').each(function (index, elem) {
        var currentVal, optionSelector, selectedOptionDate;
        currentVal = $(elem).val();
        optionSelector = 'select[name="' + $(elem).prop('name') + '"] option:selected';
        selectedOptionDate = $(optionSelector).data('date');
        if (currentSelectedDate === selectedOptionDate && selectedOptionDate !== 'tbc') {
          dateAlreadySelected = true;
          dateSelectedTwice++;
        }
        if (currentVal === '') {
          stillEmpty = true;
          return true;
        }
      });
      if (dateAlreadySelected && dateSelectedTwice >= 2) {
        sameDates();
        $(this).val("");
        stillEmpty = true;
      }
      if (!stillEmpty) {
        return $('.add-to-cart-btn').prop('disabled', false);
      } else {
        return $('.add-to-cart-btn').prop('disabled', true);
      }
    });
    enrolmart_nlpopup_expires = $("#popup1").data("expires");
    enrolmart_nlpopup_delay = $("#popup1").data("delay") * 1000;
    enrolmart_open_nlpopup = function () {
      console.log('opening popup now');
      return $('#popup1').css({
        visibility: 'visible',
        display: 'block',
        opacity: 1
      });
    };
    $('#popup1 .close').on('click', function (e) {
      $.cookie('enrolmart_nlpopup', 'closed', {
        expires: enrolmart_nlpopup_expires,
        path: '/'
      });
      $('#popup1').css({
        visibility: 'hidden',
        opacity: 0
      });
      return e.preventDefault();
    });
    if ($.cookie('enrolmart_nlpopup') !== 'closed') {
      console.log('timer started');
      setTimeout(enrolmart_open_nlpopup, enrolmart_nlpopup_delay);
    }
  });

  $(document).ready(function () {
    $("#popular-courses").owlCarousel({
      lazyLoad: true,
      responsive: {
        0: {
          items: 1,
          dots: true
        },
        580: {
          items: 2,
          dots: true
        },
        1000: {
          items: 3,
          dots: true
        },
        1200: {
          items: 4,
          dots: true,
          nav: true
        }
      }
    });
    $("#popular-products").owlCarousel({
      lazyLoad: true,
      responsive: {
        0: {
          items: 1,
          dots: true
        },
        580: {
          items: 2,
          dots: true
        },
        1000: {
          items: 3,
          dots: true
        },
        1200: {
          items: 4,
          dots: true,
          nav: true
        }
      }
    });
    $("#owl-demo2").owlCarousel({
      items: 1,
      lazyLoad: true,
      navigation: true,
      itemsDesktop: [1199, 1],
      itemsDesktopSmall: [1000, 1],
      itemsTablet: [780, 1],
      itemsTabletSmall: false,
      itemsMobile: [580, 1]
    });
    return $('.submit_button').click(function () {
      var submit_button;
      console.log('Button clicked');
      submit_button = $(this);
      return $(submit_button).parent('form').trigger('submit');
    });
  });

  $('.billing_add').on('click', function () {
    $('.billing_add').removeClass('active');
    $('.billing_add').find('input[type="radio"]').prop('checked', false);
    $(this).addClass('active');
    return $(this).find('input[type="radio"]').prop('checked', true);
  });
}).call(undefined);

(function () {
  $(document).ready(function () {
    $('#parentHorizontalTab').easyResponsiveTabs({
      type: 'default',
      width: 'auto',
      fit: true,
      tabidentify: 'hor_1',
      activate: function activate(event) {
        var $info, $name, $tab;
        $tab = $(this);
        $info = $('#nested-tabInfo');
        $name = $('span', $info);
        $name.text($tab.text());
        return $info.show();
      }
    });
    $('#profileTab').easyResponsiveTabs({
      type: 'default',
      width: 'auto',
      fit: true,
      tabidentify: 'hor_1',
      activate: function activate(event) {
        var $info, $name, $tab;
        $tab = $(this);
        $info = $('#nested-tabInfo');
        $name = $('span', $info);
        $name.text($tab.text());
        return $info.show();
      }
    });
    $('#ChildVerticalTab_1').easyResponsiveTabs({
      type: 'vertical',
      width: 'auto',
      fit: true,
      tabidentify: 'ver_1',
      activetab_bg: '#fff',
      inactive_bg: '#F5F5F5',
      active_border_color: '#c1c1c1',
      active_content_border_color: '#5AB1D0'
    });
    return $('#parentVerticalTab').easyResponsiveTabs({
      type: 'vertical',
      width: 'auto',
      fit: true,
      closed: 'accordion',
      tabidentify: 'hor_1',
      activate: function activate(event) {
        var $info, $name, $tab;
        $tab = $(this);
        $info = $('#nested-tabInfo2');
        $name = $('span', $info);
        $name.text($tab.text());
        return $info.show();
      }
    });
  });
}).call(undefined);



},{}]},{},[1]);
